<template>
  <AdaptiveSlider
    :swiper-option="optionSwiper"
    wrapper-class="lg:mb-8 overflow-y-hidden"
  >
    <template #default="{ slide }">
      <component
        :is="slide"
        v-for="(promo, index) in promos"
        :key="index"
        :class="isDesktop ? 'banner-desktop' : 'banner-mobile'"
        class="w-[328px] flex-shrink-0 cursor-pointer rounded-2xl lg:w-[432px]"
        @click.prevent="
          onPromotionBannerClicked({ promo, position: index + 1 })
        "
      >
        <div class="flex flex-col gap-y-[8px]">
          <div class="flex items-center">
            <div class="mr-2 block h-3 w-[3px] bg-red-dark lg:hidden"></div>
            <span class="mr-2 truncate text-base font-bold lg:text-2xl">{{
              promo.name
            }}</span>
            <MdiArrowRight class="text-sm text-black-primary" />
          </div>
          <div class="relative h-[197px] lg:mb-3 lg:h-[259px]">
            <HhImage
              class="absolute left-0 top-0 h-full w-full rounded-2xl object-cover object-center"
              :sources="parseImg(promo).sources"
              :src="parseImg(promo).source"
              :alt="promo.name"
              :use-mutator="true"
              :width="isDesktop ? 432 : 328"
              :height="isDesktop ? 259 : 197"
            />
          </div>

          <div v-if="promo.description" class="min-w-0 truncate">
            <div
              class="truncate text-sm font-normal text-black-primary lg:text-xl lg:font-semibold"
              v-html="promo.description"
            ></div>
            <div
              class="truncate text-xs font-normal text-gray-secondary lg:text-lg"
              v-html="promo.descriptionExt"
            ></div>
          </div>
        </div>
      </component>
    </template>
  </AdaptiveSlider>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { toRefs } from "vue";
import type { GetBannerAPIResponse } from "~/types/Banner";
import HhImage from "~/components/HhImage.vue";
import useClientTypeStore from "~/stores/clientType";
import AdaptiveSlider from "~/section/AdaptiveSlider.vue";
import MdiArrowRight from "~icons/mdi/arrow-right";
import type { HomepageModelEvent } from "~/types/Home";

const optionSwiper = {
  loop: false,
  slidesPerView: 1,
  spaceBetween: -24,
  centeredSlides: true,
  grabCursor: true,
  breakpoints: {
    600: {
      slidesPerView: 3,
      spaceBetween: 24,
      loop: false,
      centeredSlides: false,
      grabCursor: true,
    },
  },
};

const clientTypeStore = useClientTypeStore();
const { isDesktop } = storeToRefs(clientTypeStore);

interface Props {
  promos: GetBannerAPIResponse[];
}

const emits = defineEmits<{
  (e: "on-promotion-banner-clicked", data: HomepageModelEvent): void;
}>();
const props = defineProps<Props>();
const { promos } = toRefs(props);

function parseImg(promo: any) {
  let promoSource = "";
  if (isDesktop.value) {
    promoSource =
      Array.isArray(promo.desktopVersions) && promo.desktopVersions.length
        ? promo.desktopVersions[0].url
        : "";
  } else {
    promoSource =
      Array.isArray(promo.mobileVersions) && promo.mobileVersions.length
        ? promo.mobileVersions[0].url
        : "";
  }
  const sources = [
    {
      source: promoSource,
      format: "webp",
      breakpoint: {
        maxWidth: 500,
      },
      width: 328,
      height: 197,
      useMutator: true,
    },
    {
      source: promoSource,
      format: "webp",
      breakpoint: {
        minWidth: 768,
      },
      width: 432,
      height: 259,
      useMutator: true,
    },
  ];

  return {
    source: promoSource,
    sources,
  };
}

function onPromotionBannerClicked({
  promo,
  position,
}: {
  promo: GetBannerAPIResponse;
  position: number;
}) {
  const promotionBannerPayload = {
    sectionName: "Promotion",
    sectionTemplate: "promotion_banner",
    targetUrl: promo.targetUrl || "",
    position: position.toString() || "0",
    bannerName: promo.name || "",
  } as HomepageModelEvent;
  emits("on-promotion-banner-clicked", promotionBannerPayload);
}
</script>

<script lang="ts">
export default {
  name: "PromotionView",
};
</script>

<style lang="scss" scoped>
.banner-desktop {
  width: 410px !important;
  position: relative;
}
.banner-mobile {
  height: 100% !important;
  transition: all 200ms linear;
  position: relative;
  @apply mr-2;
}

.banner-mobile:first-child {
  @apply ml-4;
}

:deep(.banner-mobile .truncate > *),
:deep(.banner-desktop .truncate > *) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.promo-image-cover {
  @apply absolute bottom-0 left-0 right-0 top-0;
}

.swiper-button-next,
.swiper-button-prev {
  @apply top-[35%] h-10 w-10 rounded-full bg-white shadow-lg;
}

.promo-slider-next {
  right: -55px;
}
.promo-slider-prev {
  left: -55px;
}
</style>
